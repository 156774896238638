import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import brand1 from "../../Assets/images/brand/brand-1.png";
import brand2 from "../../Assets/images/brand/brand-2.png";
import brand3 from "../../Assets/images/brand/brand-3.png";
import brand4 from "../../Assets/images/brand/brand-4.png";
import brand5 from "../../Assets/images/brand/brand-5.png";
import brand6 from "../../Assets/images/brand/brand-6.png";

const Brands = () => {
  return (
    <section className="brand-area">
      <div className="container">
        <div className="border-section-title">
          <h4 className="title">We’ve been mentioned in Below Brands</h4>
        </div>
        <div className="swiper brandSwiper-active">
          <Swiper
            centeredSlides={true}
            loop={true}
            speed={500}
            slidesPerView={2}
            spaceBetween={40}
            autoplay={{
              delay: 3000,
            }}
            breakpoints={{
              992: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <img src={brand1} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand2} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand3} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand4} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand5} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand6} alt="Routes and Tours" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand3} alt="Routes and Tours" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Brands;
